.faq-page-container.container {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 48px;
}

.faq-page-container h1 {
    font-size: 36px !important;
    margin-top: 24px;
}

.faq-page-container .faq-container-inner {
    max-width: 800px;
    text-align: left;
}

.faq-page-container .faq-container-inner h3 {
    font-size: 22px;
    margin: 36px 0 8px;
}
