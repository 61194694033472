.about-us-page-container.container {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 48px;
}

.about-us-page-container h1 {
    font-size: 36px !important;
    margin-top: 24px;
}

.about-us-page-container .about-us-container-inner {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.about-us-page-container .about-us-container-inner img {
    max-width: 700px;
    width: 100%;
    margin: 24px 0;
}
