#search-map {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    resize: both;
}

.search-view-container .search-title {
    font-size: 40px;
    margin: 16px 0 32px;
}

.search-view-container .route-item-column {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    position: relative;
}

.search-view-container .filters-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.search-view-container .filters-container .field:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
}

.search-view-container .filters-container .search-button {
    margin-left: auto;
}

.search-view-container .route-items-container {
    height: calc(100vh - 140px);
}

.search-view-container .route-items-column,
.search-view-container .map-column {
    padding: 0;
}

.search-view-container .route-items-column {
    margin-right: 16px;
    height: 100%;
}

.search-view-container .route-items-column h2 {
    font-size: 24px;
}

.search-view-container .route-items-subheader-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.search-view-container .route-cards-container {
    margin-top: 16px;
    overflow: scroll;
    height: calc(100% - 73px);
}

.search-view-container .route-item-container {
    width: 100%;
    margin: 0;
    color: #161616;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 4px 16px rgba(45, 45, 45, 0.05);
    cursor: pointer;
}

.search-view-container .route-item-container:hover {
    background: #f7f7f7;
}

.search-view-container .route-item-container:not(:last-child) {
    margin-bottom: 8px;
}

.search-view-container .route-item-container p {
    font-size: 14px;
}

.search-view-container .route-item-container .route-labels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    margin-top: 8px;
}

.search-view-container .route-item-container .route-labels-container span:not(:last-child) {
    margin-right: 8px;
}

.search-view-container .route-item-column:first-child:after {
    content: "";
    height: 70%;
    width: 1px;
    background: #efefef;
    position: absolute;
    right: 0;
    top: 15%;
}

.search-view-container .route-item-column:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.search-view-container .route-item-column .route-time {
    font-size: 36px;
    line-height: 36px;
}

.search-view-container .info-window-container {
    max-width: 300px;
}

.search-view-container .info-window-container p {
    margin: 8px 0;
}

.search-view-container .map-column {
    position: relative;
    height: 100%;
}

.search-view-container .expand-map-button {
    position: absolute;
    top: 60px;
    left: 10px;
    z-index: 3;
}

.search-view-container .mobile-button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.search-view-container .mobile-pane-buttons button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
}

.search-view-container .mobile-pane-buttons button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@media (max-width: 768px) {
    .search-view-container .route-items-column {
        margin-right: 0;
    }
    
    .search-view-container .route-item-container .columns {
        display: flex;
    }
}