#root {
  font-family: 'Space Grotesk', sans-serif;
}

.page-content {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 16px 16px;
  background-color: #EFEFEF;
}

.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5,
.page-content h6,
.page-content label {
  font-family: 'Overpass', sans-serif;
  font-weight: 600;
  font-size: unset;
  color: #2D2D2D;
}

#root .columns {
  margin: 0;
}

#root select {
  border-radius: 25px;
  font-size: 14px;
}

#root .select:not(.is-multiple):not(.is-loading)::after {
  margin-top: -9px;
}

#root input {
  border-radius: 6px;
  font-size: 14px;
}