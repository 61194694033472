.page-footer {
    width: 100%;
    background-color: #161616;
    padding: 56px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.page-footer h3 {
    font-weight: 600;
    font-size: 18px;
    color: white;
    margin: 16px 0 40px;
}

.page-footer span {
    color: #a9a9a9;
}

.page-footer .social-icon-wrapper {
    margin: 0 2px;
    padding: 4px;
    cursor: pointer;
}

.page-footer .social-icon {
    height: 28px;
    width: 28px; 
}

.page-footer .social-icon path {
    fill: white;
}

.page-footer .logo-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.page-footer .logo-row > img {
    height: 40px;
    width: auto;
}

.page-footer .logo-row > *:not(:last-child) {
    margin-right: 20px;
}