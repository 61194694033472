#detail-map {
    width: 100%;
    height: auto;
    min-height: 300px;
    max-height: 400px;
    background-position: center;
    background-size: cover;
    resize: both;
    /* background-image: url('/images/mock-detail-map.png'); */
}

.detail-description {
    margin: 16px 0 24px;
}

.detail-view-container .detail-view-column {
    display: flex;
    flex-direction: column;
}

.detail-view-container .route-title {
    font-size: 40px;
    margin: 16px 0;
}

.detail-view-container .detail-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.detail-view-container .detail-card h3 {
    margin: 0 0 8px;
}

.detail-view-container .detail-card button {
    margin-top: 8px;
}

.detail-view-container .detail-stats-card {
    margin-bottom: 16px;
}