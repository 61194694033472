.navbar-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    height: 60px;
    font-weight: 600;
    background-color: #EFEFEF;
}

.navbar-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    margin-left: 8px;
}

.navbar-items .navbar-item {
    cursor: pointer;
    padding: 0;
    margin: 0 12px;
    transition: all ease 0.3s;
    font-size: 14px;
    color: #2D2D2D !important;
    border-bottom: 2px solid #EFEFEF;
}

.navbar-items .navbar-item:hover {
    background-color: unset;
    border-color: rgb(215 80 44 / 60%);;
}

.navbar-items .navbar-item.active {
    border-color: #D7502C;
}

.navbar-items .navbar-item-split {
    flex-grow: 1;
}

.navbar-container .navbar-icon,
.navbar-container .navbar-icon img {
    height: 18px;
    width: auto;
}