.detail-description {
    margin: 16px 0 24px;
}

.detail-view-container .route-description {
    margin-bottom: 24px;
}

.detail-view-container .route-title {
    font-size: 40px;
    margin: 16px 0;
}

.detail-view-container .detail-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.detail-view-container h2 {
    font-size: 26px !important;
    margin-bottom: 16px;
}

.route-input-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;
}

.route-input-container label {
    width: 30%;
    line-height: 36px;
}

.route-input-container input, 
.route-input-container textarea,
.route-input-container select {
    width: 70%;
    padding: 8px;
    box-sizing: border-box;
}

.route-input-container textarea {
    height: 150px;
}

.detail-view-container button {
    padding: 8px 24px;
}