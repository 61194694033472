#header-bg-image {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    background-position: center;
    background-size: cover;
    resize: both;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 64px 48px;
}

.landing-page-container.container,
.landing-page-container-inner.container {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.landing-page-container-inner.container {
    max-width: 1080px !important;
}

.landing-page-container .header-title {
    font-size: 64px;
    margin: 0 0 8px;
    color: white;
}

.landing-page-container .header-subtitle {
    font-size: 24px;
    margin: 0;
    color: white;
}

.landing-page-container .header-subtitle:hover {
    cursor: pointer;
    text-decoration: underline;
}

.landing-page-container .landing-page-subheader {
    font-size: 24px;
    margin: 48px 0 24px;
}

.landing-page-container .landing-page-body-copy {
    margin-bottom: 36px; 
}

.landing-page-container .columns {
    margin: 0;
}

.landing-page-container .info-card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    text-align: center;
    padding: 32px;
    max-width: 400px;
    box-shadow: 0px 4px 18px 0px #2D2D2D0D;
    border-radius: 6px;
    background-color: white;
}

.landing-page-container .info-card:not(:first-child) {
    margin-left: 16px;
}

@media screen and (max-width: 768px ) {
    .landing-page-container .info-card:not(:first-child) {
        margin-left: 0;
        margin-top: 16px;
    }   
}

.landing-page-container .info-card h3 {
    margin: 16px 0 8px;
}

.landing-page-container .info-card p {
    margin: 0 0 16px;
}

.landing-page-container .info-card .card-icon-wrapper {
    flex-grow: 1;
}

.landing-page-container .info-card .card-icon {
    width: 120px;
}

.landing-page-container .info-card button {
    border-color: #354692;
    color: #354692;
}

.landing-page-container .info-card button:hover {
    background-color: rgba(53, 70, 146, 0.05);
}
